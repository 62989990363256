<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-6">
                <h5 class="mb-0">
                  {{ accion }} Solicitud: <b v-if="id">{{ id }}</b>
                </h5>
              </div>

              <div class="col-md-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Hidrocarburos</li>
                  <li class="breadcrumb-item active">Transporte Fluvial</li>
                  <li class="breadcrumb-item active">Solicitudes</li>
                  <li class="breadcrumb-item active">
                    Formulario {{ accion }}
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div :class="accion == 'Editar' ? 'col-md-12' : 'col-md-12'">
                <div class="card">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-8">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <label for="">Sitio de Cargue</label>
                              <v-select
                                :class="[
                                  $v.sitio.$invalid ? 'is-invalid' : 'is-valid',
                                  $store.getters.getDarkMode
                                    ? 'dark-vselect'
                                    : '',
                                ]"
                                v-model="sitio"
                                label="nombre"
                                :options="listasForms.sitios"
                                placeholder="Sitio"
                                @input="getSelectSitio()"
                                :disabled="form.estado == 2"
                                class="form-control form-control-sm p-0"
                              >
                              </v-select>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label for="">Fecha Solicitud</label>
                              <input
                                type="date"
                                class="form-control form-control-sm"
                                v-model="form.fecha"
                                :class="
                                  $v.form.fecha.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                                :disabled="form.estado == 2"
                                @input="validaLimiteSolicitud()"
                              />
                            </div>
                          </div>
                          <div class="col-md-12">
                            <div class="form-group">
                              <label for="">Estado</label>
                              <select
                                class="form-control form-control-sm"
                                v-model="form.estado"
                                :class="
                                  $v.form.estado.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                                @change="save()"
                                :disabled="form.estado == 2"
                              >
                                <option value="">Seleccione...</option>
                                <option
                                  v-for="estado in listasForms.estados"
                                  :key="estado.id"
                                  :value="estado.numeracion"
                                  :disabled="
                                    estado.numeracion === 2 && accion == 'Crear'
                                  "
                                >
                                  {{ estado.descripcion }}
                                </option>
                              </select>
                            </div>
                          </div>
                          <div class="col-md-12">
                            <div class="form-group">
                              <label for="">Observación</label>
                              <textarea
                                :disabled="form.estado == 2"
                                v-model="form.observaciones"
                                class="form-control form-control-sm"
                              ></textarea>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4" v-if="form.id">
                        <div class="card">
                          <div class="card-header">
                            <div class="row">
                              <div class="col-md-6">
                                <h4 class="card-title mt-2 font-weight-bold ">
                                  Archivos
                                </h4>
                              </div>
                              <div class="col-md-6">
                                <button
                                  class="btn bg-gradient-info btn-sm float-right shadow"
                                  type="button"
                                  @click="plantilla()"
                                  v-if="
                                    $store.getters.can(
                                      'hidrocarburos.regaliasNominaciones.export'
                                    )
                                  "
                                >
                                  <i
                                    class="fa fa-download"
                                    aria-hidden="true"
                                  ></i>
                                  Plantilla
                                </button>
                              </div>
                              <div class="card-body">
                                <input
                                  :disabled="form.estado == 1"
                                  type="file"
                                  class="form-control"
                                  id="fileDocu"
                                  @change="fileUpload"
                                  accept=".xls,.xlsx"
                                />
                              </div>
                            </div>
                            <div class="form-group col-md-12">
                              <div class="card card-outline card-navy">
                                <div class="card-header pt-2 pb-2">
                                  <h3 class="card-title">
                                    <h6>
                                      <i class="fas fa-file-signature"></i>
                                      Nota:
                                    </h6>
                                  </h3>
                                </div>
                                <div class="card-body">
                                  <div class="row">
                                    <ol>
                                      <li>
                                        El formato debe estar completo sin
                                        ningún campo en blanco.
                                      </li>
                                      <li>
                                        El campo de Fecha cargue debe llevar un
                                        formato (“yyyy–mm-dd”).
                                      </li>
                                      <li>
                                        El campo de Hora cargue debe llevar un
                                        formato (“HH:MM”).
                                      </li>
                                      <li>
                                        El campo de Fecha solicitud debe llevar
                                        un formato (“yyyy–mm-dd”).
                                      </li>
                                    </ol>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="guidesData && Object.keys(guidesData).length > 0"
                    class="card"
                  >
                    <div
                      v-for="(convoyGroup, convoyNumber) in guidesData"
                      :key="convoyNumber"
                      class="col-md-12 mb-4"
                    >
                      <div class="card">
                        <div class="card-header text-center">
                          <h2 class="card-title fw-bold">
                            Convoy Número: {{ convoyNumber }}
                          </h2>
                        </div>
                        <table
                          class="table table-hover text-center table-bordered table-striped "
                        >
                          <thead
                            class="bg-dark bg-frontera-top-left pt-2 pb-2 text-white text-center"
                          >
                            <tr>
                              <th>Barcaza</th>
                              <th>Guia</th>
                              <th>Fecha Cargue</th>
                              <th>Producto</th>
                              <th>Volumen Frontera GSV</th>
                              <th>Volumen Trafigura GSV</th>
                              <th>Volumen Total NSV</th>
                              <th>Acciones</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(item, index) in convoyGroup"
                              :key="index"
                            >
                              <td>{{ item.barcaza }}</td>
                              <td>{{ item.guia }}</td>
                              <td>{{ item.fecha_cargue }}</td>
                              <td>{{ item.producto }}</td>
                              <td>{{ item.volumenFronteraGSV }}</td>
                              <td>{{ item.volumenTrafiguraGSV }}</td>
                              <td>
                                {{
                                  item.volumenFronteraGSV +
                                    item.volumenTrafiguraGSV
                                }}
                              </td>
                              <td>
                                <button
                                  @click="openModal(item)"
                                  class="btn btn-info"
                                >
                                  Ver Guías
                                </button>
                                <button
                                  @click="openModalGuia(item)"
                                  class="btn btn-info"
                                >
                                  Asignar Guia
                                </button>
                                <button
                            type="button"
                            class="btn btn-sm btn-danger"
                         
                          >
                            <i class="fas fa-trash-alt"></i>
                          </button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <DetGuiasFluvialForm ref="DetGuiasFluvialForm" />
        <SolicitudGuiasAsignadas ref="SolicitudGuiasAsignadas" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import Loading from "../../../../components/Loading";
import { required, helpers } from "vuelidate/lib/validators";
import vSelect from "vue-select";
import DetGuiasFluvialForm from "./DetGuiasFluvialForm";
import SolicitudGuiasAsignadas from "./SolicitudGuiasAsignadas";

const timeFormat = helpers.regex(
  "timeFormat",
  /^([0-1]?[0-9]|2[0-3]):([0-5][0-9])(:[0-5][0-9])?$/
);
export default {
  name: "SolicitudForm",
  components: {
    Loading,
    vSelect,
    DetGuiasFluvialForm,
    SolicitudGuiasAsignadas,
  },
  data() {
    return {
      guidesData: null,
      convoyGroup: [],
      selectedGuides: [],

      file: null,
      asignacion: {},
      nsvDeseado: 0,
      volumenAlcanzado: 0,
      volumenFaltante: 0,
      asignaciones: [],
      guiasSeleccionadas: [],
      cargando: false,
      sitio: null,
      guias: [],
      showDetails: {},
      selectedFechas: {},
      selectedGuias: {},
      totalNSV: 0,
      totalGSV: 0,
      id: "",
      metodo: "",
      listasForms: {
        estados: [],
        sitios: [],
        productos: [],
        tipoProductos: [],
      },
      form: {
        sitio_id: null,
        fecha: null,
        estado: null,
      },
      accion: "",
      solicitudEmpresas: {},
      cantidades_empresas: {
        totalLarga: 0,
        totalCorta: 0,
      },
      val_fecha: false,
    };
  },
  validations: {
    form: {
      fecha: {
        required,
      },
      estado: {
        required,
      },
    },
    sitio: {
      required,
    },
  },
  methods: {
    show() {
      this.cargando = true;
      axios
        .get("/api/hidrocarburos/solicitudes/" + this.id)
        .then((response) => {
          this.form = response.data;
          this.cargando = false;
          if (this.form.fecha_venci_postulacion != null) {
            let data_fecha_venc = this.form.fecha_venci_postulacion.split(" ");
            this.form.venc_fecha = data_fecha_venc[0];
            this.form.venc_hora = data_fecha_venc[1];
          }
        });
    },
    back() {
      return this.$router.replace("/Hidrocarburos/SolicitudesFluviales");
    },

    getSelectSitio() {
      if (this.sitio !== null) {
        this.form.sitio_id = this.sitio.id;
      } else {
        this.sitio = null;
        this.form.sitio_id = null;
      }
    },
    getProductos() {
      this.cargando = true;
      axios.get("/api/hidrocarburos/Productos/lista").then((response) => {
        this.listasForms.productos = response.data;
        this.cargando = false;
      });
    },
    getEstados() {
      this.cargando = true;
      axios.get("/api/lista/28").then((response) => {
        this.listasForms.estados = response.data;
        this.cargando = false;
      });
    },
    getTipoProductos() {
      this.cargando = true;
      axios.get("/api/lista/7").then((response) => {
        console.log("Tipo de productos:", response.data);
        this.listasForms.tipoProductos = response.data;
        this.cargando = false;
      });
    },
    getSitios() {
      this.cargando = true;
      axios.get("/api/admin/sitios/lista").then((response) => {
        this.listasForms.sitios = response.data;
        this.cargando = false;
        if (this.$route.params.accion == "Editar") {
          this.sitio = this.listasForms.sitios.find(
            (n) => n.id === this.form.sitio_id
          );
        }
      });
    },
    async save() {
      if (this.form.estado == 2) {
        await this.$swal({
          title: "¿Está seguro de poner en estado definitivo la Solicitud?",
          text: "No podrá volver a editar la Solicitud",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Sí, Definitivo",
        }).then((result) => {
          if (!result.value) {
            this.form.estado = 1;
            return;
          }
        });
      }
      this.cargando = true;
      axios({
        method: this.metodo,
        url: "/api/hidrocarburos/solicitudesFluviales/save",
        data: this.form,
      })
        .then((response) => {
          Object.assign(this.form, response.data);

          this.id = this.form.id;
          this.accion = "Editar";
          this.metodo = "POST";

          this.$swal({
            icon: "success",
            title: "La solicitud se guardó exitosamente...",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        })
        .catch((e) => {
          let res = Object.assign({}, e);
          this.$swal({
            icon: "error",
            title:
              "Ocurrió un error, vuelva a intentarlo..." +
              res.response.data.message,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        })
        .finally(() => {
          this.cargando = false;
        });
    },
    async fileUpload(event) {
      const file = event.target.files[0];
      const solicitudFluvialId = this.form.id;

      if (!file) {
        alert("Por favor, selecciona un archivo.");
        return;
      }

      const formData = new FormData();
      formData.append("file", file);
      formData.append("solicitud_fluvial_id", solicitudFluvialId);

      try {
        console.log("Enviando archivo...");

        const response = await axios.post(
          "/api/hidrocarburos/solicitudesFluviales/import",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        let result_content = "";
        // Verificar si la respuesta tiene errores
        if (response.data.error && response.data.error.length > 0) {
          result_content += `<div><p class="text-danger"><strong>La plantilla no se cargó correctamente.</strong></p></div>`;
          result_content += `<h4>Errores encontrados:</h4>`;
          response.data.error.forEach((err) => {
            result_content += `<div><small>Fila: ${err.fila} - Errores:</small></div>`;
            err.errores.forEach((mensaje) => {
              result_content += `<div><small>${mensaje}</small></div>`;
            });
          });
        } else {
          result_content = `<div><p class="text-success"><strong>La plantilla se cargó exitosamente.</strong></p></div>`;
          this.guidesData = response.data.convoy;
        }

        await this.$swal({
          title: "Resultado de la carga.",
          html: result_content,
          icon:
            response.data.error && response.data.error.length > 0
              ? "error"
              : "success",
          showCancelButton: false,
          confirmButtonText: "Ok",
          backdrop: `rgba(0,0,123,0.4) left top no-repeat`,
          allowOutsideClick: false,
        });
      } catch (e) {
        console.error("Error al subir el archivo:", e);

        // Si hubo error 400, extraemos el detalle de los errores
        if (e.response && e.response.data) {
          let result_content = `<div><p class="text-danger"><strong>La plantilla no se cargó correctamente.</strong></p></div>`;

          if (e.response.data.error && e.response.data.error.length > 0) {
            result_content += `<h4>Errores encontrados:</h4>`;
            e.response.data.error.forEach((err) => {
              result_content += `<div><small>Fila: ${err.fila} - Errores:</small></div>`;
              err.errores.forEach((mensaje) => {
                result_content += `<div><small>${mensaje}</small></div>`;
              });
            });
          }

          await this.$swal({
            title: "Resultado de la carga.",
            html: result_content,
            icon: "error",
            showCancelButton: false,
            confirmButtonText: "Ok",
            backdrop: `rgba(0,0,123,0.4) left top no-repeat`,
            allowOutsideClick: false,
          });
        } else {
          // En caso de un error general
          this.$swal({
            icon: "error",
            title: `Ocurrió un error: ${e.message || "Vuelva a intentarlo..."}`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
    },

    init() {
      this.accion = this.$route.params.accion;
      this.id = this.$route.params.id;
      if (this.id) {
        this.accion = "Editar";
        this.form = this.$route.params.data_edit;
        this.metodo = "POST";
      } else {
        this.accion = "Crear";
        this.metodo = "POST";
      }
    },

    plantilla() {
      this.cargando = true;
      axios
        .get("/api/hidrocarburos/solicitudesFluviales/exportar")
        .then((response) => {
          let data = response.data;
          location.href = data.url;
          this.cargando = false;
          this.$swal({
            icon: "success",
            title: response.data.message,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
      this.cargando = false;
    },

    openModal(item) {
      // Llama al método openModal del componente hijo y pasa las guías seleccionadas
      this.$refs.SolicitudGuiasAsignadas.openModal(item);
    },
    openModalGuia(item) {
      // Llama al método openModal del componente hijo y pasa las guías seleccionadas
      this.$refs.DetGuiasFluvialForm.openModalGuia(item);
    },
  },
  beforeMount() {
    this.init();
  },
  mounted() {
    this.getEstados();
    this.getSitios();
    this.getProductos();
    this.getTipoProductos();
  },
};
</script>
<style>
.gradient-buttons .btn {
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0.2) 49%,
    rgba(0, 0, 0, 0.15) 51%,
    rgba(0, 0, 0, 0.05)
  );
  background-repeat: repeat-x;
}
.card {
  margin: 10px;
  box-shadow: 0 5px 10px -2.5px;
}
.text-sobrante {
  font-weight: bold;
  color: rgb(75, 147, 171);
}
.bg-sobrante {
  background-color: rgb(92, 152, 174);
}
.bg-gsv {
  background-color: rgba(41, 114, 39, 0.78);
}
.text-gsv {
  font-weight: bold;
  color: rgb(75, 171, 75);
}
</style>
