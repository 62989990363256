var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hold-transition"},[(_vm.cargando)?_c('loading'):_vm._e(),_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"content-wrapper"},[_c('section',{staticClass:"content-header"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('h5',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.accion)+" Solicitud: "),(_vm.id)?_c('b',[_vm._v(_vm._s(_vm.id))]):_vm._e()])]),_c('div',{staticClass:"col-md-6"},[_c('ol',{staticClass:"breadcrumb float-sm-right"},[_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":"/"}},[_vm._v("Home")])],1),_c('li',{staticClass:"breadcrumb-item active"},[_vm._v("Hidrocarburos")]),_c('li',{staticClass:"breadcrumb-item active"},[_vm._v("Transporte Fluvial")]),_c('li',{staticClass:"breadcrumb-item active"},[_vm._v("Solicitudes")]),_c('li',{staticClass:"breadcrumb-item active"},[_vm._v(" Formulario "+_vm._s(_vm.accion)+" ")])])])])])]),_c('section',{staticClass:"content"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{class:_vm.accion == 'Editar' ? 'col-md-12' : 'col-md-12'},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-8"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":""}},[_vm._v("Sitio de Cargue")]),_c('v-select',{staticClass:"form-control form-control-sm p-0",class:[
                                _vm.$v.sitio.$invalid ? 'is-invalid' : 'is-valid',
                                _vm.$store.getters.getDarkMode
                                  ? 'dark-vselect'
                                  : '',
                              ],attrs:{"label":"nombre","options":_vm.listasForms.sitios,"placeholder":"Sitio","disabled":_vm.form.estado == 2},on:{"input":function($event){return _vm.getSelectSitio()}},model:{value:(_vm.sitio),callback:function ($$v) {_vm.sitio=$$v},expression:"sitio"}})],1)]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":""}},[_vm._v("Fecha Solicitud")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.fecha),expression:"form.fecha"}],staticClass:"form-control form-control-sm",class:_vm.$v.form.fecha.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid',attrs:{"type":"date","disabled":_vm.form.estado == 2},domProps:{"value":(_vm.form.fecha)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.form, "fecha", $event.target.value)},function($event){return _vm.validaLimiteSolicitud()}]}})])]),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":""}},[_vm._v("Estado")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.estado),expression:"form.estado"}],staticClass:"form-control form-control-sm",class:_vm.$v.form.estado.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid',attrs:{"disabled":_vm.form.estado == 2},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "estado", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){return _vm.save()}]}},[_c('option',{attrs:{"value":""}},[_vm._v("Seleccione...")]),_vm._l((_vm.listasForms.estados),function(estado){return _c('option',{key:estado.id,attrs:{"disabled":estado.numeracion === 2 && _vm.accion == 'Crear'},domProps:{"value":estado.numeracion}},[_vm._v(" "+_vm._s(estado.descripcion)+" ")])})],2)])]),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":""}},[_vm._v("Observación")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.observaciones),expression:"form.observaciones"}],staticClass:"form-control form-control-sm",attrs:{"disabled":_vm.form.estado == 2},domProps:{"value":(_vm.form.observaciones)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "observaciones", $event.target.value)}}})])])])]),(_vm.form.id)?_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col-md-6"},[(
                                  _vm.$store.getters.can(
                                    'hidrocarburos.regaliasNominaciones.export'
                                  )
                                )?_c('button',{staticClass:"btn bg-gradient-info btn-sm float-right shadow",attrs:{"type":"button"},on:{"click":function($event){return _vm.plantilla()}}},[_c('i',{staticClass:"fa fa-download",attrs:{"aria-hidden":"true"}}),_vm._v(" Plantilla ")]):_vm._e()]),_c('div',{staticClass:"card-body"},[_c('input',{staticClass:"form-control",attrs:{"disabled":_vm.form.estado == 1,"type":"file","id":"fileDocu","accept":".xls,.xlsx"},on:{"change":_vm.fileUpload}})])]),_vm._m(1)])])]):_vm._e()])]),(_vm.guidesData && Object.keys(_vm.guidesData).length > 0)?_c('div',{staticClass:"card"},_vm._l((_vm.guidesData),function(convoyGroup,convoyNumber){return _c('div',{key:convoyNumber,staticClass:"col-md-12 mb-4"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header text-center"},[_c('h2',{staticClass:"card-title fw-bold"},[_vm._v(" Convoy Número: "+_vm._s(convoyNumber)+" ")])]),_c('table',{staticClass:"table table-hover text-center table-bordered table-striped"},[_vm._m(2,true),_c('tbody',_vm._l((convoyGroup),function(item,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(item.barcaza))]),_c('td',[_vm._v(_vm._s(item.guia))]),_c('td',[_vm._v(_vm._s(item.fecha_cargue))]),_c('td',[_vm._v(_vm._s(item.producto))]),_c('td',[_vm._v(_vm._s(item.volumenFronteraGSV))]),_c('td',[_vm._v(_vm._s(item.volumenTrafiguraGSV))]),_c('td',[_vm._v(" "+_vm._s(item.volumenFronteraGSV + item.volumenTrafiguraGSV)+" ")]),_c('td',[_c('button',{staticClass:"btn btn-info",on:{"click":function($event){return _vm.openModal(item)}}},[_vm._v(" Ver Guías ")]),_c('button',{staticClass:"btn btn-info",on:{"click":function($event){return _vm.openModalGuia(item)}}},[_vm._v(" Asignar Guia ")]),_vm._m(3,true)])])}),0)])])])}),0):_vm._e()])])])])]),_c('DetGuiasFluvialForm',{ref:"DetGuiasFluvialForm"}),_c('SolicitudGuiasAsignadas',{ref:"SolicitudGuiasAsignadas"})],1)])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-6"},[_c('h4',{staticClass:"card-title mt-2 font-weight-bold"},[_vm._v(" Archivos ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group col-md-12"},[_c('div',{staticClass:"card card-outline card-navy"},[_c('div',{staticClass:"card-header pt-2 pb-2"},[_c('h3',{staticClass:"card-title"},[_c('h6',[_c('i',{staticClass:"fas fa-file-signature"}),_vm._v(" Nota: ")])])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('ol',[_c('li',[_vm._v(" El formato debe estar completo sin ningún campo en blanco. ")]),_c('li',[_vm._v(" El campo de Fecha cargue debe llevar un formato (“yyyy–mm-dd”). ")]),_c('li',[_vm._v(" El campo de Hora cargue debe llevar un formato (“HH:MM”). ")]),_c('li',[_vm._v(" El campo de Fecha solicitud debe llevar un formato (“yyyy–mm-dd”). ")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',{staticClass:"bg-dark bg-frontera-top-left pt-2 pb-2 text-white text-center"},[_c('tr',[_c('th',[_vm._v("Barcaza")]),_c('th',[_vm._v("Guia")]),_c('th',[_vm._v("Fecha Cargue")]),_c('th',[_vm._v("Producto")]),_c('th',[_vm._v("Volumen Frontera GSV")]),_c('th',[_vm._v("Volumen Trafigura GSV")]),_c('th',[_vm._v("Volumen Total NSV")]),_c('th',[_vm._v("Acciones")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"btn btn-sm btn-danger",attrs:{"type":"button"}},[_c('i',{staticClass:"fas fa-trash-alt"})])
}]

export { render, staticRenderFns }