<template>
  <div
    v-if="showModal"
    class="modal fade show"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Guías Asignadas</h5>
          <button
            type="button"
            class="close"
            @click="closeModal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-4 col-6 d-flex align-items-stretch flex-column">
              <div class="small-box bg-sobrante">
                <div class="inner textWhite">
                  <h3>
                    {{ guias.volumenFronteraGSV }}
                  </h3>
                  <p>Volumen Frontera</p>
                </div>
                <div class="icon">
                  <i class="fas fa-oil-can textWhite"></i>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-6 d-flex align-items-stretch flex-column">
              <div class="small-box bg-cumple">
                <div class="inner textWhite">
                  <h3>
                    {{ totalGSV }}
                  </h3>
                  <p>Volumen GSV</p>
                </div>
                <div class="icon">
                  <i class="fas fa-oil-can  textWhite"></i>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-6 d-flex align-items-stretch flex-column">
              <div class="small-box bg-cumple">
                <div class="inner textWhite">
                  <h3>
                    {{ totalNSV }}
                  </h3>
                  <p>Volumen NSV</p>
                </div>
                <div class="icon">
                  <i class="fas fa-oil-can  textWhite"></i>
                </div>
              </div>
            </div>
          </div>

          <table class="table table-hover text-center table-bordered table-striped ">
            <thead  class="bg-dark bg-frontera-top-left pt-2 pb-2 text-white text-center">
              <tr>
                <th>Guía</th>
                <th>Fecha Descargue</th>
                <th>Tipo Producto</th>
                <th>Producto liquido</th>
                <th>Barriles GSV</th>
                <th>Barriles NSV</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(guia, index) in guias.guias_seleccionadas"
                :key="index"
              >
                <td>
                  {{ guia.det_guia.guia.numero }}-{{
                    guia.det_guia.guia.digito_verificacion
                  }}
                </td>
                <td>
                  {{ guia.det_guia.guia.cierre_guias[0].fecha_hora_descargue }}
                </td>
                <td>{{ guia.det_guia.tipo_producto.nombre }}</td>
                <td>{{ guia.det_guia.producto_liquido.nombre }}</td>
                <td>{{ guia.det_guia.guia.cierre_guias[0].barriles_gsv }}</td>
                <td>{{ guia.det_guia.guia.cierre_guias[0].barriles_nsv }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="closeModal">
            Cerrar
          </button>   
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SolicitudGuiasAsignadas",
  data() {
    return {
      showModal: false,
      guias: [],
      totalGSV: 0,
      totalNSV: 0,
    };
  },
  methods: {
    openModal(guias) {
      this.guias = guias;
      this.totalGSV = guias.guias_seleccionadas.reduce((sum, guia) => {
        const gsv =
          parseFloat(guia.det_guia.guia.cierre_guias[0]?.barriles_gsv) || 0; 
        return sum + gsv;
      }, 0);

      this.totalNSV = guias.guias_seleccionadas.reduce((sum, guia) => {
        const nsv =
          parseFloat(guia.det_guia.guia.cierre_guias[0]?.barriles_nsv) || 0; 
        return sum + nsv;
      }, 0);

      this.showModal = true; 
    },
    closeModal() {
      this.showModal = false;
    },
  },
};
</script>

<style scoped>
.modal {
  display: block;
  background-color: rgba(0, 0, 0, 0.5);
}
.bg-sobrante {
  background-color: rgb(131, 197, 222);
}
.bg-cumple {
  background-color: rgb(171, 219, 171);
}
</style>
